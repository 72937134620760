import * as React from 'react';

import { useCheckout } from './CheckoutContext';

export const ProcessingSpinner: React.FunctionComponent = () => {
  const { state } = useCheckout();

  if (!state.paymentProcessing) {
    return <></>;
  }

  return (
    <div id="loading" className="purple-loading">
      <h1>Please wait while we process your order...</h1>
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div className="object" id="object_one"></div>
          <div className="object" id="object_two"></div>
          <div className="object" id="object_three"></div>
          <div className="object" id="object_four"></div>
        </div>
      </div>
    </div>
  );
};
