import * as React from 'react';

import { CheckoutForm, CheckoutProvider } from '../components/checkout';
import { Col, Container, Row } from 'react-bootstrap';
import { Footer, NavMenu, PageTitle } from '../components/shared';

import { Elements } from '@stripe/react-stripe-js';
import { OrderDetails } from '../components/checkout/OrderDetails';
import { ProcessingSpinner } from '../components/checkout/ProcessingSpinner';
import { loadStripe } from '@stripe/stripe-js';
import { useScreenInfo } from '../utils/ScreenInfo';

export const CheckoutPage = () => {
  const stripePromise = loadStripe(process.env.GATSBY_APP_STRIPE_KEY);
  const screen = useScreenInfo();

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutProvider>
          <ProcessingSpinner />
          {!screen.isPhone && <div style={{ backgroundColor: '#f4f4f9', position: 'fixed', top: 0, bottom: 0, right: 0, width: '43%', zIndex: -1 }}></div>}
          <div>
            <PageTitle subtitle="Checkout" />
            <NavMenu fixed noNav />

            <Container className="wsmainwp" style={{ minHeight: '84vh' }}>
              <Row className="mb-50">
                <Col md={7}>
                  <CheckoutForm />
                </Col>
                <Col md={{ span: 4, offset: 1 }}>
                  <OrderDetails />
                </Col>
              </Row>
            </Container>
            <Footer />
          </div>
        </CheckoutProvider>
      </Elements>
    </>
  );
};

export default CheckoutPage;
