import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { Reducer } from 'react';
import { loadState, saveState } from '../../utils/Session';

import { getApiClient } from '../../utils/GetApiClient';
import { trackPurchase } from '../../utils/Tracking';

const sessionKey = 'checkout';

// BASE STATE
export interface CheckoutState {
  situsId: string;
  productId: number;
  situsAddress: string;
  ownerName: string;
  couponCode: string;
  referralSectionClosed: boolean;
  calculateResult: any;
  orderNumber: string;
  paymentProcessing: boolean;
  errorMessage: string;
}

export const defaultState: CheckoutState = {
  situsId: '',
  productId: 0,
  situsAddress: '',
  ownerName: '',
  couponCode: '',
  referralSectionClosed: false,
  calculateResult: {},
  orderNumber: '',
  paymentProcessing: false,
  errorMessage: '',
};

export enum CheckoutReducerTypes {
  TogglePaymentProcessing,
  SetReferralCoupon,
  SaveCalculateResult,
  SaveOrderNumber,
  SetErrorMessage,
}

interface CheckoutActions {
  togglePaymentProcessing(status: boolean): void;
  setReferralCoupon(couponCode: string): void;
  getOrderCalculation(): void;
  processOrder(values: any): void;
  processComplete(orderId: string, result: any): void;
  processStripeError(orderId: string, result: any): void;
}

interface CheckoutContextValue {
  state: CheckoutState;
  dispatch: React.Dispatch<any>;
  actions: CheckoutActions;
}

const CheckoutContext = React.createContext<CheckoutContextValue>({
  state: {} as CheckoutState,
  dispatch: () => null,
  actions: {
    togglePaymentProcessing: () => null,
    setReferralCoupon: () => null,
    getOrderCalculation: () => null,
    processOrder: () => null,
    processComplete: () => null,
    processStripeError: () => null,
  },
});

type ReducerAction = {
  type: CheckoutReducerTypes;
  payload: any;
};

const checkoutStateReducer: Reducer<CheckoutState, ReducerAction> = (state: CheckoutState, action: any) => {
  switch (action.type) {
    case CheckoutReducerTypes.TogglePaymentProcessing: {
      return saveState(sessionKey, { ...state, paymentProcessing: action.payload });
    }
    case CheckoutReducerTypes.SetReferralCoupon: {
      return saveState(sessionKey, { ...state, couponCode: action.payload, referralSectionClosed: action.payload !== '' });
    }
    case CheckoutReducerTypes.SaveCalculateResult: {
      return saveState(sessionKey, { ...state, calculateResult: action.payload });
    }
    case CheckoutReducerTypes.SaveOrderNumber: {
      return saveState(sessionKey, { ...state, orderNumber: action.payload });
    }
    case CheckoutReducerTypes.SetErrorMessage: {
      return saveState(sessionKey, { ...state, errorMessage: action.payload });
    }
    default:
      return state;
  }
};

interface CheckoutProviderProps {
  children: React.ReactNode;
}

export const CheckoutProvider: React.FunctionComponent<CheckoutProviderProps> = (props: CheckoutProviderProps) => {
  const elements = useElements();
  const stripe = useStripe();

  let initialState = loadState(sessionKey);
  if (initialState === undefined) {
    initialState = defaultState;
  } else {
    initialState = { ...defaultState, ...initialState };
  }

  saveState(sessionKey, initialState);

  const [state, dispatch] = React.useReducer(checkoutStateReducer, initialState);

  function togglePaymentProcessing(status: boolean): void {
    dispatch({ type: CheckoutReducerTypes.TogglePaymentProcessing, payload: status });
  }

  function setReferralCoupon(couponCode: string): void {
    dispatch({ type: CheckoutReducerTypes.SetReferralCoupon, payload: couponCode });
  }

  function getOrderCalculation(): void {
    getApiClient()
      .post<any>('/api/orders/calculate', { productId: state.productId, couponCode: state.couponCode })
      .then((response) => {
        dispatch({ type: CheckoutReducerTypes.SaveCalculateResult, payload: response.data });
      })
      .catch((error) => console.log(error));
  }

  function processOrder(values: any): void {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    togglePaymentProcessing(true);

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement == null) {
      return;
    }

    const request = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      situsId: state.situsId,
      couponCode: state.couponCode,
      referrals: state.couponCode ? [values.emailReferral1, values.emailReferral2, values.emailReferral3] : null,
      productId: state.productId,
    };

    getApiClient()
      .post<any>(state.orderNumber ? '/api/orders/update/' + state.orderNumber : '/api/orders/create', request)
      .then(async (response) => {
        var clientSecret = response.data.clientSecret;
        dispatch({ type: CheckoutReducerTypes.SaveOrderNumber, payload: response.data.orderId });

        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: values.nameOnCard,
              address: {
                postal_code: values.zipCode,
              },
            },
          },
        });

        if (payload.error) {
          processStripeError(response.data.orderId, payload.error);
        } else {
          processComplete(response.data.orderId, payload.paymentIntent);
        }
      })
      .catch((error) => {
        console.log(error);
        togglePaymentProcessing(false);
      });
  }

  function processComplete(orderId: string, result: any): void {
    trackPurchase(orderId, state.couponCode);
    getApiClient()
      .post<any>('/api/orders/complete/' + orderId, { stripeId: result.id, stripeResponseJson: JSON.stringify(result) })
      .then((response) => {
        document.location.href = '/thank-you';
        sessionStorage.clear();
      })
      .catch((error) => console.log(error));
  }

  function processStripeError(orderId: string, result: any): void {
    getApiClient()
      .post<any>('/api/orders/error/' + orderId, { stripeId: result.payment_intent.id, stripeResponseJson: JSON.stringify(result), errorType: result.code })
      .then((response) => {
        dispatch({ type: CheckoutReducerTypes.SetErrorMessage, payload: result.message });
        togglePaymentProcessing(false);
      })
      .catch((error) => console.log(error));
  }

  return (
    <CheckoutContext.Provider
      value={{
        state,
        dispatch,
        actions: {
          togglePaymentProcessing,
          setReferralCoupon,
          getOrderCalculation,
          processOrder,
          processComplete,
          processStripeError,
        },
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = (): CheckoutContextValue => React.useContext(CheckoutContext);
