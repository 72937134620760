import * as React from 'react';
import * as Yup from 'yup';

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Col, Form, Row } from 'react-bootstrap';

import { CheckoutField } from './CheckoutField';
import { Formik } from 'formik';
import classNames from 'classnames';
import { useCheckout } from './CheckoutContext';

export const CheckoutForm: React.FunctionComponent = () => {
  const { state, actions } = useCheckout();

  React.useEffect(() => {
    actions.getOrderCalculation();
  }, [state.couponCode, state.productId]);

  // const initialValues = {
  //   firstName: 'Test1',
  //   lastName: 'User1',
  //   email: 'test@testing.com',
  //   emailReferral1: 'test1@testing.com',
  //   emailReferral2: 'test2@testing.com',
  //   emailReferral3: 'test3@testing.com',
  //   nameOnCard: 'Test User',
  //   zipCode: '90210',
  // };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    emailReferral1: '',
    emailReferral2: '',
    emailReferral3: '',
    nameOnCard: '',
    zipCode: '',
  };

  const CheckoutSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    emailReferral1: Yup.string().email('Invalid email'),
    emailReferral2: Yup.string().email('Invalid email'),
    emailReferral3: Yup.string().email('Invalid email'),
    nameOnCard: Yup.string().required('Required'),
    zipCode: Yup.string().required('Required').length(5, 'Invalid zip (5 digits)'),
  });

  const handleReferralSubmit = (formik: any) => {
    formik.validateField('emailReferral1');
    formik.validateField('emailReferral2');
    formik.validateField('emailReferral3');

    if (
      !formik.errors.hasOwnProperty('emailReferral1') &&
      !formik.errors.hasOwnProperty('emailReferral2') &&
      !formik.errors.hasOwnProperty('emailReferral3') &&
      formik.values.emailReferral1 &&
      formik.values.emailReferral2 &&
      formik.values.emailReferral3
    ) {
      actions.setReferralCoupon('referrals');
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={CheckoutSchema} onSubmit={(values) => actions.processOrder(values)}>
      {(formik) => (
        <Form id="checkout" onSubmit={formik.handleSubmit}>
          <div className="p-4">
            <h2>Checkout</h2>
            <Row>
              <Col>
                <CheckoutField formId="firstName" title="First Name" formik={formik} />
              </Col>
              <Col>
                <CheckoutField formId="lastName" title="Last Name" formik={formik} />
              </Col>
            </Row>
            <CheckoutField formId="email" title="Email" formik={formik} />
          </div>

          <div className={classNames('bg-tra-purple p-4', { 'd-none': state.referralSectionClosed })} style={{ borderRadius: 16 }}>
            <h4>Get 20% off when you refer us to 3 friends.</h4>
            <p className="p-sm">We will send them an email and let them know they can check their assessment</p>
            <CheckoutField formId="emailReferral1" title="Email Referral #1" formik={formik} />
            <CheckoutField formId="emailReferral2" title="Email Referral #2" formik={formik} />
            <CheckoutField formId="emailReferral3" title="Email Referral #3" formik={formik} />
            <div className="d-grid mt-10">
              <button className="btn btn-yellow yellow-hover btn-sm" type="button" onClick={() => handleReferralSubmit(formik)}>
                Give Me 20% Off
              </button>
            </div>
          </div>
          <div className="p-4">
            <CheckoutField formId="nameOnCard" title="Name on Card" formik={formik} />
            <Form.Group className="mb-2">
              <Form.Label className="mb-0">Credit Card Number</Form.Label>
              <div className="field-wrapper">
                <CardNumberElement
                  id="card-number-element"
                  options={{ style: { base: { fontSize: '16px', lineHeight: '1.5' }, invalid: { color: '#9e2146' } } }}
                />
              </div>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-0">Expiration</Form.Label>
                  <div className="field-wrapper">
                    <CardExpiryElement id="card-expiry-element" options={{ style: { base: { fontSize: '16px', lineHeight: '1.5' } } }} />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-0">CVC</Form.Label>
                  <div className="field-wrapper">
                    <CardCvcElement id="card-cvc-element" options={{ style: { base: { fontSize: '16px', lineHeight: '1.5' } } }} />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <CheckoutField formId="zipCode" title="Zip Code" formik={formik} />
              </Col>
            </Row>
            <div className="d-grid mt-20">
              <button className="btn btn-yellow yellow-hover btn-lg" type="submit">
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
