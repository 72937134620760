import * as React from 'react';

import { useCheckout } from './CheckoutContext';

export const OrderDetails: React.FunctionComponent = () => {
  const { state } = useCheckout();

  if (!state.calculateResult) {
    return <></>;
  }

  return (
    <>
      <div className="py-4">
        <h2>Order</h2>
        {state.calculateResult.products &&
          Object.keys(state.calculateResult.products).map((key) => (
            <div key={key} className="d-flex justify-content-between">
              <div className="invoice-item-title">
                {key}
                <div className="invoice-item-subtitle">
                  - {state.situsAddress}
                  <br />- {state.ownerName}
                </div>
              </div>
              <div className="invoice-item-value">${state.calculateResult.products[key]}</div>
            </div>
          ))}

        {state.calculateResult.coupons &&
          Object.keys(state.calculateResult.coupons).map((key) => (
            <div key={key} className="d-flex justify-content-between">
              <div className="invoice-item-title">{key}</div>
              <div className="invoice-item-value">${state.calculateResult.coupons[key]}</div>
            </div>
          ))}

        <div className="d-flex justify-content-between total-due">
          <div className="invoice-item-title purple-color">Total Due</div>
          <div className="invoice-item-value purple-color">${state.calculateResult.total}</div>
        </div>
      </div>
      {state.errorMessage && (
        <div className="mt-25 p-3 bg-tra-red red-color" style={{ borderRadius: 16 }}>
          <h5>Card Declined!</h5> {state.errorMessage}
        </div>
      )}
    </>
  );
};
