import * as React from 'react';

import { Form } from 'react-bootstrap';
import { FormikProps } from 'formik';

interface CheckoutFieldProps {
  formId: string;
  title: string;
  formik: FormikProps<any>;
}

export const CheckoutField: React.FunctionComponent<CheckoutFieldProps> = (props: CheckoutFieldProps) => {
  const isInValid = (): boolean => {
    return props.formik.errors.hasOwnProperty(props.formId) && props.formik.touched.hasOwnProperty(props.formId);
  };

  return (
    <Form.Group className="mb-2">
      <Form.Label className="mb-0">{props.title}</Form.Label>
      <Form.Control
        id={props.formId}
        name={props.formId}
        type="text"
        onChange={props.formik.handleChange}
        onBlur={props.formik.handleBlur}
        value={props.formik.values[props.formId]}
        isInvalid={isInValid()}
        autoComplete="off"
      />
      <Form.Control.Feedback type="invalid">{props.formik.errors[props.formId]}</Form.Control.Feedback>
    </Form.Group>
  );
};
